<template>
  <div>
    <h2>Übersicht der Lieferscheine</h2>

    <dashboard-element>
      <dashboard-head title="Lieferscheine" />
      <dashboard-table class="p-3">
        <dashboard-card
          v-for="lieferschein in lieferscheine"
          :key="lieferschein.id"
          :title="lieferschein.belegnr"
          :content="lieferschein.content"
          :link="`/lieferschein/${lieferschein.id}`"
        />
      </dashboard-table>
    </dashboard-element>
  </div>
</template>

<script>
import DashboardHead from "../../components/dashboard/DashboardHead.vue";
import DashboardTable from "../../components/dashboard/DashboardTable.vue";
import DashboardCard from "../../components/dashboard/DashboardCard.vue";
import DashboardElement from "../../components/dashboard/DashboardElement.vue";
import { supabase } from "../../plugins/supabase";

export default {
  components: {
    DashboardHead,
    DashboardTable,
    DashboardCard,
    DashboardElement
  },
  name: "LieferscheinUebersicht",
  data() {
    return {
      lieferscheine: []
    };
  },
  mounted() {
    this.loadInitialData();

    supabase
      .from("lieferscheine")
      .on("*", payload => {
        console.log("Change received!", payload);
        this.loadInitialData();
      })
      .subscribe();
  },
  methods: {
    async loadInitialData() {
      const { data: lieferscheine, error } = await supabase
        .from("lieferscheine")
        .select("*")
        .range(0, 9);

      error && console.log(error);

      this.lieferscheine = lieferscheine;
    }
  }
};
</script>
